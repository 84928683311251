import React, { ReactNode, Props, ReactInstance, Component } from 'react';
import AriaRole from '../../AriaRole';
import { SVGCloseIcon } from '../SVGIcons';
import isFunction from 'lodash/isFunction';
import classNames from 'clsx';
import { WithT } from 'i18next';

export interface ModalDialogHeaderProps extends Props<ReactInstance>, WithT {
    /**
     * To force usages of <ModalDialogHeader> to provide child content
     */
    children: ReactNode;

    /**
     * Called when the modal close button is clicked. Note that if no onClose
     * callback is passed, no close button will be rendered.
     */
    onClose?: () => void;

    /**
     * Allows to "custyle" the ModalDialogHeader root element.
     */
    className?: string;

    /*
     * Allows to "cuzstyle" the ModalDialogHeader close button
     */
    closeButtonClassName?: string;
}

/**
 * Header part of a modal dialog
 */
export class ModalDialogHeader extends Component<ModalDialogHeaderProps> {
    public render(): JSX.Element {
        let { onClose, className, closeButtonClassName } = this.props;
        className = classNames('ModalDialog-Header', this.props.className);
        return (
            <div className={className} role={AriaRole.PRESENTATION}>
                {isFunction(onClose) && (
                    <button
                        onClick={onClose}
                        className={classNames('ModalDialog-Header-CloseButton', closeButtonClassName)}
                    >
                        <SVGCloseIcon t={this.props.t} />
                    </button>
                )}
                {this.props.children}
            </div>
        );
    }
}
