import React, { Props, ReactInstance, FunctionComponent, ReactNode } from 'react';
import AriaRole from '../../AriaRole';
import classNames from 'clsx';

/**
 * Checks if the device system is iOS
 */
function isiOSDevice(): boolean {
    return /(iPad|iPhone|iPod)/g.test(navigator.userAgent);
}

export interface ModalDialogBodyProps extends Props<ReactInstance> {
    /**
     * To force usages of <ModalDialogBody> to provide child content
     */
    children: ReactNode;

    /**
     * Allows to add an optional extra className to the modal body
     */
    className?: string;
}

/**
 * Body part of a modal dialog
 */

export const ModalDialogBody: FunctionComponent<ModalDialogBodyProps> = ({
    className,
    children,
}: ModalDialogBodyProps): JSX.Element => {
    const rootClassName: string = classNames('ModalDialog-Body', className, {
        'ModalDialog-Body-auto': isiOSDevice(),
    });
    return (
        <div className={rootClassName} role={AriaRole.PRESENTATION}>
            {children}
        </div>
    );
};
