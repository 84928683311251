/**
 * Tracks 'nested' calls to functions defined on ally.maintain .
 *
 * Nested calls might be needed in the following situation:
 * - a dialog is opened, and use ally.maintain.hidden and ally.maintain.tabFocus to trap
 * keyboard and screen reader focus inside the modal dialog.
 *
 * This component tracks calls to these allyjs utilities, and if a second call is made to these
 * utilities, all other calls are disabled. The calls are redone when handler returned
 * by the second call is disengaged.
 */
class AllyMaintainStack {
    /**
     * Stack containing argument and returned values of the tracked calls.
     */
    private callStack: AllyMaintainCall[];

    /**
     * Function whose calls are tracked
     */
    private func: (options: Ally.AllyMaintainOptions) => Ally.AllyService;

    public constructor(func: (options: Ally.AllyMaintainOptions) => Ally.AllyService) {
        this.func = func;
        this.callStack = [];
    }

    public register(options: Ally.AllyMaintainOptions): void {
        // disable the currently active call
        const activeCall: AllyMaintainCall = this.peekLastCall();
        if (activeCall != null) {
            this.disableCall(activeCall);
        }

        // make the new call
        const call: AllyMaintainCall = {
            options: options,
            service: this.func(options),
        };

        this.callStack.push(call);
    }

    public disengageLastCall(): void {
        // disengage the current active call
        const call: AllyMaintainCall | undefined = this.callStack.pop();
        if (call == null) {
            // tslint:disable-next-line:no-console
            // console.error('No ally.maintain call active!'); // FIXME: Use proper logging lib
            return;
        }
        this.disableCall(call);

        // re-active the previous call
        const previousCall: AllyMaintainCall = this.peekLastCall();
        if (previousCall != null) {
            this.remakeCall(previousCall);
        }
    }

    private disableCall(call: AllyMaintainCall): void {
        if (call.service != null) {
            call.service.disengage();
        }
    }

    private remakeCall(call: AllyMaintainCall): void {
        call.service = this.func(call.options);
    }

    private peekLastCall(): AllyMaintainCall {
        const lastIndex: number = this.callStack.length - 1;
        return this.callStack[lastIndex];
    }
}

/**
 * Utility object for tracking calls to functions defined on ally.maintain
 */
interface AllyMaintainCall {
    options: Ally.AllyMaintainOptions;
    service: Ally.AllyService;
}

export default AllyMaintainStack;
