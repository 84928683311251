import * as ally from 'ally.js';
import AllyMaintainStack from './AllyMaintainStack';

/**
 * General a11y utils
 */

/**
 * Utility stack allows multiple calls of ally.maintain.hidden, managed automatically by an AllyMaintainStack
 */
export const allyMaintainHidden: AllyMaintainStack = new AllyMaintainStack(ally.maintain.hidden);

/**
 * Utility stack allows multiple calls of ally.maintain.tabFocus, managed automatically by an AllyMaintainStack
 */
export const allyMaintainTabFocus: AllyMaintainStack = new AllyMaintainStack(ally.maintain.tabFocus);

/**
 * Return the first focusable element inside a container.
 * @param {HTMLElement} The container in which first focusable element should be found in.
 */
export function firstFocusable(contentContainer: HTMLElement | null): HTMLElement | null {
    return contentContainer != null ? ally.query.focusable({ context: contentContainer })[0] : null;
}

/**
 * Checks if the activeElement is a valid focus element. Logic extracted in a separate function for testability
 *
 * The logic behind the checks:
 * - most browsers set document.activeElement to document.body in case the element which
 * was focused was removed.
 * - IE11 sometimes document.activeElement to null in most cases, and {} in case the call is made from an iframe
 * - in some cases, the container of the document.activeElement is hidden through CSS,
 * by adding visibility: hidden. In this case, document.activeElement does not change,
 * but the focus is still effectively lost, since elements hidden through CSS aren't part
 * of the accessibility tree. The extra check to `ally.is.focusable` returns true in this case.
 */
function isValidFocusTarget(activeElement: Element | null): boolean {
    return activeElement != null && activeElement !== document.body && ally.is.focusable(activeElement);
}

/**
 * Returns true if focus was "lost", false otherwise
 */
export function isFocusLost(): boolean {
    return !isValidFocusTarget(document.activeElement);
}
