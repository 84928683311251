/* eslint-disable react/no-deprecated */
import classNames from 'clsx';
import React, { Component, PropsWithChildren } from 'react';
import AriaRole from '../../../AriaRole';
import EventKeys from '../../../EventKeys';
import { isFocusLost, firstFocusable, allyMaintainTabFocus, allyMaintainHidden } from '../Utils';
import isFunction from 'lodash/isFunction';
import defer from 'lodash/defer';
import noop from 'lodash/noop';

/**
 *  Decorates a child panel with A11y functionality, as described in the Aria Authoring Practices Document.
 *
 *  The features added are:
 *  - ARIA mark-up for the parent div
 *  - focus first control element
 *  - tab focus can be trapped inside the dialog
 *  - all elements outside of the flyout can be hidden/disabled
 *  - can execute a close callback when the ESC key is pressed
 *  - when closed, focus is restored to the previously focused element when the dialog was rendered.
 */
export type A11yDialogDecoratorProps = PropsWithChildren<{
    /**
     * Aria label to set on the dialog
     */
    ariaLabel?: string;
    /**
     * Equivalent of aria-labelledby
     */
    ariaLabelledBy?: string;
    /**
     * Equivalent of aria-labelledby
     */
    ariaDescribedBy?: string;
    /**
     * Additional styling classname.
     */
    className?: string;
    /**
     * If this is set to true, the dialog behaves like a modal dialog
     * - focus is trapped
     * - other elements are disabled
     *
     * If this is set to true, all other elements outside of this dialog are disabled.
     * This can be used to prevent screen reader specific keyboard shortcuts which scan the
     * document for a list of button or link elements to return controls outside of this dialog.
     *
     * If this is set to true, tab focus is trapped inside the flyout. When the last element is focused
     * the TAB key will loop to the first element. When the first element is focused, SHIFT+TAB will focus
     * the last element.
     */
    isModal?: boolean;
    /**
     * This handler will be called when ESC is pressed and focus is inside this element. Pass a handler here
     * to have the flyout close on ESC.
     */
    onEscKeyPress: () => void;

    /**
     * By default true:
     * After mount or after updates resulting in a focus lost, do focus
     * the first focusable element inside the dialog.
     * Set it to false if you don't want the modal to automatically refocus. This means that you manually
     * set the focus elsewhere in the client code, for a11y.
     */
    autofocus?: boolean;

    /**
     * Whether or not the ARIA role "Dialog" is added to the decorator
     * Defaults to true
     */
    ariaRoleDialog?: boolean;
}>;

class A11yDialogDecorator extends Component<A11yDialogDecoratorProps> {
    public static defaultProps: A11yDialogDecoratorProps = {
        ariaLabel: '',
        isModal: true,
        onEscKeyPress: noop,
        autofocus: true,
        ariaRoleDialog: true,
    };

    private focusedElementAtMount: HTMLElement | null = null;
    private rootDOMNode: HTMLDivElement | null = null;

    private captureRootDOMNode(node: HTMLDivElement | null): void {
        this.rootDOMNode = node;
    }

    public constructor(props: A11yDialogDecoratorProps) {
        super(props);

        this.onEscKeyPress = this.onEscKeyPress.bind(this);
        this.captureRootDOMNode = this.captureRootDOMNode.bind(this);
    }

    public componentWillMount(): void {
        // save the previously focused element, we need to restore it when this dialog is closed
        this.focusedElementAtMount = document.activeElement as HTMLElement;
    }

    public componentDidUpdate(): void {
        if (isFocusLost() && this.props.isModal) {
            // if the element is mounted but focus is somehow lost, restore it back inside the dialog
            this.moveFocusInsideDialog();
        }
    }

    public componentDidMount(): void {
        if (this.props.isModal && this.rootDOMNode) {
            // create a focus trap inside the dialog, this makes sure Tab + Shift-Tab will cycle inside the dialog
            const ariaLiveContainer = document.querySelector('#aria-live-container');
            if (ariaLiveContainer !== null) {
                allyMaintainHidden.register({
                    filter: [this.rootDOMNode, document.querySelector('#aria-live-container')!],
                });
            }

            allyMaintainTabFocus.register({
                context: this.rootDOMNode,
            });
        }
        this.moveFocusInsideDialog();
    }

    public componentWillUnmount(): void {
        if (this.props.isModal) {
            // remove the aria-hidden on the branches
            allyMaintainHidden.disengageLastCall();

            // remove the focus trap
            allyMaintainTabFocus.disengageLastCall();
        }

        // restore the focus to the element that was focused when this dialog was created
        if (this.focusedElementAtMount != null) {
            this.focusedElementAtMount.focus();
        }
    }

    public render(): JSX.Element {
        const className: string = classNames('A11yDialogDecorator', this.props.className);

        const props: React.HTMLProps<HTMLDivElement> = {
            className,
            'aria-label': this.props.ariaLabel,
            'aria-labelledby': this.props.ariaLabelledBy,
            'aria-describedby': this.props.ariaDescribedBy,
            tabIndex: -1,
            onKeyDown: this.onEscKeyPress,
            ref: this.captureRootDOMNode,
        };

        if (this.props.ariaRoleDialog !== false) {
            props.role = AriaRole.DIALOG;
        }

        return <div {...props}>{this.props.children}</div>;
    }

    /**
     * If the currently focused element is inside the flyout,
     * do not focus another element. If the currently focused element is outside
     * of the dialog, focus the first tabbable element inside the flyout.
     */
    private moveFocusInsideDialog(): void {
        if (!this.props.autofocus) {
            return;
        }

        const isCurrentActiveElementOutsideDialog: boolean =
            document.activeElement == null ||
            this.rootDOMNode == null ||
            !this.rootDOMNode.contains(document.activeElement);

        if (isCurrentActiveElementOutsideDialog) {
            // find the first tabbable element in the dialog and focus it
            const firstFocusableFound: HTMLElement | null = firstFocusable(this.rootDOMNode);
            if (firstFocusableFound != null) {
                defer((): void => {
                    firstFocusableFound.focus();
                });
            }
        }
    }

    private onEscKeyPress(e: React.KeyboardEvent): void {
        switch (e.key) {
            case EventKeys.ESC:
                e.stopPropagation();
                if (isFunction(this.props.onEscKeyPress)) {
                    this.props.onEscKeyPress();
                }
                break;
            default:
                break;
        }
    }
}

export default A11yDialogDecorator;
