import React, { Props, ReactInstance, Component, ReactNode, CSSProperties } from 'react';
import ReactDOM from 'react-dom';
import isFunction from 'lodash/isFunction';
import classNames from 'clsx';
import AriaRole from '../../AriaRole';
import A11yDialogDecorator from '../A11y/dialog/A11yDialogDecorator';
import chroma, { Color } from 'chroma-js';
import { CSSTransition } from 'react-transition-group';

function getTransparentOverlayInlineStylesForColor(color: Color = chroma('black')): CSSProperties {
    return {
        backgroundColor: color.alpha(0.25).css(),
    };
}

/**
 * Display an accessible modal dialog.
 */
export interface ModalDialogProps extends Props<ReactInstance> {
    /**
     * To force usages of <ModalDialog> to provide childrens
     */
    children: ReactNode;

    /**
     * Whether or not the modal should be shown/open. If not set
     * to true, it is simply not rendered at all.
     */
    isOpen: boolean;

    ariaLabel: string;

    /**
     * Called when the ESC key is hit. You should then close the modal
     * by setting its `isOpen` prop to false.
     */
    onEscKeyPress: () => void;

    /**
     * Called when the user clicks outside the modal. You should then close the modal
     * by setting its `isOpen` prop to false.
     */
    onOutsideClick?: () => void;

    /**
     * The color of the transparent overlay in around the modal dialog.
     * If not set, if will defaults to black (from ModalDialog.scss).
     * This base color will eventually be applied a .25 opacity.
     */
    transparentOverlayColor?: Color;

    className?: string;

    /**
     * Whether the modal contents should fill the entire window.
     */
    fullScreen?: boolean;

    /**
     * By default true:
     * After mount or after updates resulting in a focus lost, do focus
     * the first focusable element inside the dialog.
     * Set it to false if you don't want the modal to automatically refocus.
     */
    autofocus?: boolean;
}

export class ModalDialog extends Component<ModalDialogProps> {
    private rootDOMNode: HTMLDivElement | null = null;

    public static defaultProps: Partial<ModalDialogProps> = {
        autofocus: true,
    };

    public constructor(props: ModalDialogProps) {
        super(props);
        this.onOutsideClick = this.onOutsideClick.bind(this);
        this.captureRootDOMNode = this.captureRootDOMNode.bind(this);
    }

    public componentDidUpdate(prevProps: ModalDialogProps): void {
        if (prevProps.isOpen !== this.props.isOpen) {
            this.updateBodyData(this.props.isOpen);
        }
    }

    public componentDidMount(): void {
        this.updateBodyData(this.props.isOpen);
    }

    public componentWillUnmount(): void {
        this.updateBodyData(false);
    }

    public render(): JSX.Element | null {
        if (typeof window !== 'object') {
            return null;
        }

        const rootClassName: string = classNames('ModalDialog', this.props.className, {
            'ModalDialog-transparentBackground': this.isOutsideClickable(),
            'ModalDialog-fullScreen': this.props.fullScreen,
        });

        const contentClassName: string = classNames(
            'ModalDialog-Content',
            this.props.className && `${this.props.className}-Content`
        );

        /* eslint-disable jsx-a11y/click-events-have-key-events */
        // We have a `onEscKeyPress` key event handler in the A11yDialogDecorator
        // so this jsx-a11y rule is a false positive, thus disabling it.
        return ReactDOM.createPortal(
            <CSSTransition in={this.props.isOpen} timeout={200} classNames="ModalDialog" mountOnEnter unmountOnExit>
                {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
                <div
                    className={rootClassName}
                    ref={this.captureRootDOMNode}
                    role={AriaRole.PRESENTATION}
                    onClick={this.onOutsideClick}
                    style={getTransparentOverlayInlineStylesForColor(this.props.transparentOverlayColor)}
                >
                    <A11yDialogDecorator
                        className={contentClassName}
                        ariaLabel={this.props.ariaLabel}
                        isModal={true}
                        autofocus={this.props.autofocus}
                        ariaRoleDialog={true}
                        onEscKeyPress={this.props.onEscKeyPress}
                    >
                        {this.props.children}
                    </A11yDialogDecorator>
                </div>
            </CSSTransition>,
            document.querySelector('.App') || document.body
        );
        /* eslint-enable jsx-a11y/click-events-have-key-events */
    }

    private captureRootDOMNode(node: HTMLDivElement | null): void {
        this.rootDOMNode = node;
    }

    /**
     * Used to prevent scrolling of the body when the modal is open.
     * See https://css-tricks.com/prevent-page-scrolling-when-a-modal-is-open/ for more details.
     * @param isOpen boolean. Set to true to prevent scrolling.
     */
    private updateBodyData(isOpen: boolean): void {
        if (isOpen) {
            const scrollY = window.pageYOffset;
            const body = document.body;
            body.style.position = 'fixed';
            body.style.top = `-${scrollY}px`;
        } else {
            const body = document.body;
            const scrollY = body.style.top;
            body.style.position = '';
            body.style.top = '';
            window.scrollTo(0, parseInt(scrollY || '0') * -1);
        }
    }

    private isOutsideClickable(): boolean {
        return isFunction(this.props.onOutsideClick);
    }

    private onOutsideClick(e: React.SyntheticEvent): void {
        if (isFunction(this.props.onOutsideClick)) {
            if (this.rootDOMNode != null && this.rootDOMNode === e.target) {
                this.props.onOutsideClick();
            }
        }
    }
}
